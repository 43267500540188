import React, {useState, useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { AccountBalance, PhoneInTalk, Work, Store, Tv, LocalHospital, AddShoppingCart, Business } from '@material-ui/icons';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Cookies from "components/Cookies/Cookies";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

import styles from "assets/jss/material-kit-react/views/components.js";

import logo from "assets/img/logotipo.svg";
import Presenciales from "assets/img/Presenciales.svg";
import HomeOffice from "assets/img/HomeOffice.svg";
import Colaboradores from "assets/img/Colaboradores.svg";
import Estaciones from "assets/img/Estaciones.svg";
import ventas from "assets/img/ventas.jpg";
import atencion from "assets/img/atencion.jpg";
import cobranza from "assets/img/cobranza.jpg";
import office from "assets/img/office.jpg";

import portada from "assets/img/portada.png";
import Comercial from "assets/img/Comercial.mp4";

import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';

import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
  ...styles,
  margin: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  label: {
    padding: '20px'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const matchesMax600 = useMediaQuery('(max-width:600px)');
  const matchesMin600 = useMediaQuery('(min-width:600px)');
  const matchesMin768 = useMediaQuery('(min-width:768px)');
  const matchesMin960 = useMediaQuery('(min-width:960px)');
  const matchesMin992 = useMediaQuery('(min-width:992px)');
  const matchesMin1200 = useMediaQuery('(min-width:1200px)');

  const { ...rest } = props;

  let deberes = ['Tenemos mas de 20 años de experiencia, esto le garantiza que nuestros procedimientos ya han sido probados en el mercado y brindado los mejores resultados.',
                 'Nos adaptamos a las nuevas tendencias y la migración digital, mediante las actualizaciones en nuevas tecnologías de la mano de desarrollo de servicios que se adecuen a sus requerimientos.',
                 'Al contratarnos no solo ahorra recursos económicos, también es un gran apoyo en cuestión de tiempo, ya que puede centrar toda su energía en otros asuntos que priorizar.'];

  let titulos = ['Experiencia',
                 'Innovación',
                 'Ahorro'];

  const [experiencia, setExperiencia] = useState('');
  const [innovacion, setInnovacion] = useState('');
  const [ahorro, setAhorro] = useState('');

  const [load, setLoad] = useState(false);

  useEffect(()=>{
    setLoad(true);
    setExperiencia(titulos[0]);
    setInnovacion(titulos[1]);
    setAhorro(titulos[2]);
  }, []);

  const images = [
    {
      url: ventas,
      title: 'Ventas',
      ruta: '/soluciones?p=ventas'
    },
    {
      url: atencion,
      title: 'Atención a clientes',
      ruta: '/soluciones?p=atencion'
    },
    {
      url: cobranza,
      title: 'Cobranza',
      ruta: '/soluciones?p=cobranza'
    },
    {
      url: office,
      title: 'Back Office',
      ruta: '/soluciones?p=back'
    },
  ];

  const proyectos = [
    {
      icon: AccountBalance,
      text: 'Instituciones Financieras',
      color: '#EFB509'
    },
    {
      icon: PhoneInTalk,
      text: 'Telecomunicaciones',
      color: '#CD7213'
    },
    {
      icon: Work,
      text: 'Aseguradoras',
      color: '#002C54'
    },
    {
      icon: Store,
      text: 'Restaurantes',
      color: '#16253D'
    },
    {
      icon: Tv,
      text: 'Entretenimiento',
      color: '#EFB509'
    },
    {
      icon: LocalHospital,
      text: 'Servicios de Salud',
      color: '#CD7213'
    },
    {
      icon: AddShoppingCart,
      text: 'E-Commerce',
      color: '#002C54'
    },
    {
      icon: Business,
      text: 'Embajadas',
      color: '#16253D'
    }
  ];

  const handleClick = (ruta) => {
    props.history.push(ruta);
  };

  return (
    <div>
      <Header
        brand={logo}
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />

      <div style={{marginTop: "100px"}} className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h2 style={{color: "black", textAlign: 'center', fontFamily: "'Manjari', sans-serif"}}>Imagen Calidad y Resultado</h2>
                    <h4 style={{color: "black", textAlign: 'center', fontFamily: "'Manjari', sans-serif"}}>Tu conexión al éxito</h4>
                  </GridItem>
                  <GridItem style={{textAlign: "center", marginBottom: '40px'}} xs={12} sm={12} md={12}>
                    <div style={{margin: matchesMin1200 || matchesMin992 ? "auto" : "", width: matchesMin1200 || matchesMin992 ? "80%" : ""}}>
                      <Player
                        playsInline
                        poster={portada}
                        src={Comercial}
                        autoPlay
                        muted
                        loop
                      />
                    </div>
                  </GridItem>                  
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
              <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                Soluciones
              </Typography>
            </GridItem>
            {images.map((image) => (
              <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={6}>
                <ButtonBase
                disableTouchRipple
                  key={image.title}
                  className={classes.image}
                  style={{
                    width: "100%",
                    marginBottom: "30px"
                  }}
                  onClick={() => handleClick(image.ruta)}
                >
                  <span
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${image.url})`,
                    }}
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      {image.title}
                      <span className={classes.imageMarked} />
                    </Typography>
                  </span>
                </ButtonBase>
              </GridItem>
            ))}
          </GridContainer>
        </div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
              <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                ¿Por qué delegarnos tus deberes?
              </Typography>
            </GridItem>
            <GridItem className={classes.margin} style={{textAlign: "center", marginBottom: "30px"}} xs={12} sm={12} md={12}>
              <Fab
                color="primary"
                aria-label="add"
                style={{width: "300px", height: "300px", background: "#002C54"}}
                onMouseOver={() => setExperiencia(deberes[0])}
                onMouseLeave={() => setExperiencia(titulos[0])}
                classes={{
                  label: classes.label
                }}
              >
                {experiencia}
              </Fab>
              <Fab
                color="primary"
                aria-label="add"
                style={{width: "300px", height: "300px", background: "#CD7213"}}
                onMouseOver={() => setInnovacion(deberes[1])}
                onMouseLeave={() => setInnovacion(titulos[1])}
                classes={{
                  label: classes.label
                }}
              >
                {innovacion}
              </Fab>
              <Fab
                color="primary"
                aria-label="add"
                style={{width: "300px", height: "300px", background: "#EFB509"}}
                onMouseOver={() => setAhorro(deberes[2])}
                onMouseLeave={() => setAhorro(titulos[2])}
                classes={{
                  label: classes.label
                }}
              >
                {ahorro}
              </Fab>
            </GridItem>
          </GridContainer>
        </div>
        <div style={{backgroundImage: "linear-gradient(to right, #EFB509 , white)"}}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                <Typography variant="h4" style={{color: "black", marginTop: "50px", marginBottom: "40px"}} gutterBottom>
                  Nosotros en números
                </Typography>
              </GridItem>
              <GridItem style={{marginBottom: "30px"}} xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <Paper style={{minHeight: "300px", display: "flex", flexDirection: "column", marginBottom: "40px"}} elevation={3}>
                      <Typography variant="h6" style={{color: "#EFB509", textAlign: "center", textTransform: "none"}} gutterBottom>
                        Estaciones de trabajo
                      </Typography>
                      <div style={{textAlign: "center"}}>
                        <img width={(matchesMin768 || matchesMin1200 ? "250px" : "250px") ? matchesMin960 : "150px"} src={Estaciones}></img>
                      </div>
                      <CountUp end={150} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <div style={{textAlign: "center", marginTop: "auto"}}>
                              <span style={{color: "#EFB509", fontSize: "50px"}}>+</span>
                              <span style={{color: "#EFB509", fontSize: "50px"}} ref={countUpRef} />
                            </div>
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </Paper>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Paper style={{minHeight: "300px", display: "flex", flexDirection: "column", marginBottom: "40px"}} elevation={3}>
                      <Typography variant="h6" style={{color: "#CD7213", textAlign: "center", textTransform: "none"}} gutterBottom>
                        Colaboradores
                      </Typography>
                      <div style={{textAlign: "center"}}>
                        <img width={(matchesMin768 || matchesMin1200 ? "250px" : "250px") ? matchesMin960 : "150px"} src={Colaboradores}></img>
                      </div>
                      <CountUp end={130} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <div style={{textAlign: "center", marginTop: "auto"}}>
                              <span style={{color: "#CD7213", fontSize: "50px"}}>+</span>
                              <span style={{color: "#CD7213", fontSize: "50px"}} ref={countUpRef} />
                            </div>
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </Paper>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Paper style={{minHeight: "300px", display: "flex", flexDirection: "column", marginBottom: "40px"}} elevation={3}>
                      <Typography variant="h6" style={{color: "#16253D", textAlign: "center", textTransform: "none"}} gutterBottom>
                        Ejecutivos presenciales
                      </Typography>
                      <div style={{textAlign: "center"}}>
                        <img width={(matchesMin768 || matchesMin1200 ? "250px" : "250px") ? matchesMin960 : "150px"} src={Presenciales}></img>
                      </div>
                      <CountUp end={70} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <div style={{textAlign: "center", marginTop: "auto"}}>
                              <span style={{color: "#16253D", fontSize: "50px"}} ref={countUpRef} />
                              <span style={{color: "#16253D", fontSize: "50px"}}>%</span>
                            </div>
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </Paper>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Paper style={{minHeight: "300px", display: "flex", flexDirection: "column", marginBottom: "40px"}} elevation={3}>
                      <Typography variant="h6" style={{color: "#002C54", textAlign: "center", textTransform: "none"}} gutterBottom>
                        Ejecutivos Home Office
                      </Typography>
                      <div style={{textAlign: "center"}}>
                        <img width={(matchesMin768 || matchesMin1200 ? "250px" : "250px") ? matchesMin960 : "150px"} src={HomeOffice}></img>
                      </div>                  
                      <CountUp end={30} redraw={true}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <div style={{textAlign: "center", marginTop: "auto"}}>
                              <span style={{color: "#002C54", fontSize: "50px"}} ref={countUpRef} />
                              <span style={{color: "#002C54", fontSize: "50px"}}>%</span>
                            </div>
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </Paper>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
              <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                Proyectos realizados
              </Typography>
            </GridItem>
            <GridItem style={{marginBottom: "30px"}} xs={12} sm={12} md={12}>
              <GridContainer>
                {proyectos.map((item) => (
                  <GridItem xs={6} sm={6} md={3}>
                    <Paper style={{minHeight: "150px", textAlign: 'center', marginBottom: '30px'}} elevation={3}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <item.icon style={{marginTop: '50px', fontSize: '70px', color: item.color}} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Typography variant="body1" style={{color: "black", marginTop: "30px"}} gutterBottom>
                            {item.text}
                          </Typography>
                        </GridItem>
                      </GridContainer>
                    </Paper>
                  </GridItem>
                ))}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Cookies load={load} />
      <Footer />
    </div>
  );
}
