import React from "react";

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {HomeWork, Business, MonetizationOn, CreditCard, AccountBalance} from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function CobranzaSection() {
  const elementos = [
    {
      titulo: 'B2C',
      contenido: 'Contamos con la mejor atención de sus productos y servicios, mediante un sistema de cobranza especializada y personalizada a la medida de cada cliente, mediante la mejor preparación negociación, utilizando estrategias y métodos innovadores en técnicas de cobranza. Además, contamos con herramientas de control y análisis de la desviación en nuestro contact center asociado al sistema de cobranzas, lo que sumado al servicio de calidad integral que poseemos, nos lleva a ser su mejor alternativa para externalizar la gestión de cobranza.',
      icono: HomeWork,
      color: '#EFB509'
    },
    {
      titulo: 'B2B',
      contenido: 'Gestión de cobros para empresas deudoras, que buscan reducir deudas incobrables y mantener clientes activos. Buscamos asertivamente a los gerentes financieros de las empresas y utilizamos negociadores con un perfil diferente quienes, usando tecnologías innovadoras, garantizan la mejor entrega en la recuperación de crédito.',
      icono: Business,
      color: '#CD7213'
    },
    {
      titulo: 'Cobranza Tardía',
      contenido: 'Sabemos lo importante que es minimizar el impacto de las pérdidas para su empresa, por lo tanto, le ofrecemos los mejores procesos para gestionar su cartera y hacer la recuperación de la misma. La clave en esta etapa no es la eficiencia en la contactabilidad, sino en la forma y la calidad del contacto con el cliente.',
      icono: MonetizationOn,
      color: '#002C54'
    },
    {
      titulo: 'Cobranza Digital',
      contenido: 'La transformación digital en la Gestión de Cobranzas, no es otra cosa que reinventar el contacto con el Cliente, exponencializar los procesos operativos y optimizar el modelo de negocios, apoyados en la aplicación de metodología, soportado por Sistemas y potenciado a la gente que lo utiliza.',
      icono: CreditCard,
      color: '#16253D'
    },
    {
      titulo: 'Cobranza Especial',
      contenido: 'El objetivo es establecer un puente de contacto con el cliente para llegar a un acuerdo y liquidar algún saldo vencido, esto con el fin de evitar llevar el caso ante una autoridad y requerir el pago mediante la resolución de un juez. Siempre en el marco de la legalidad, fomentando las buenas prácticas y desarrollando comunicación efectiva para la persuasión del deudor a cumplir con los pagos morosos que tenga.',
      icono: AccountBalance,
      color: '#EFB509'
    }
  ];

  return(
    <GridContainer>
      <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
        <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
            Cobranza 
        </Typography>
      </GridItem>
      <GridItem style={{textAlign: "center", marginBottom: '30px'}} xs={12} sm={12} md={12}>
        <Typography style={{textAlign: "justify"}} variant="body1" gutterBottom>
            Tenemos las mejores soluciones en métodos de cobranza, agilizando el proceso para ofrecerle una mejor tasa de conversión y logrando minimizar el impacto.
            Con sistemas mejorados para la gestión de su cartera, utilizando diversos métodos de contactación (multicanal), que nos brinda una gama de servicios adecuándonos a sus necesidades y ciclo de vida de su cartera.
        </Typography>
      </GridItem>
      {elementos.map((item) => (
        <GridItem xs={12} sm={12} md={4}>
          <Paper style={{minHeight: "430px", textAlign: 'center', marginBottom: '30px'}} elevation={3}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{color: "black", marginTop: "50px"}} gutterBottom>
                  {item.titulo}
                </Typography>                    
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <item.icono style={{ fontSize: '70px', color: item.color}} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body2" style={{color: "black", marginTop: "10px", textAlign: 'justify', marginLeft: '20px', marginRight: '20px', marginBottom: '20px'}} gutterBottom>
                  {item.contenido}
                </Typography>
              </GridItem>
            </GridContainer>
          </Paper>
        </GridItem>
      ))}
    </GridContainer>
  );
}