import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: 'flex-end',
    },
    topPaperScrollBody: {
      verticalAlign: 'bottom',
    },
});

export default function Cookies(props) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [state, setState] = useState({
        aceptar: false,
        seleccionar: false
    });

    const handleClose = (event, reason) => {
        if(reason !== "backdropClick"){
            setOpen(false);
        }
    };

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const aceptarContinuar = (event) => {
        cookieSesion();
        setOpen(false);
    };

    const seleccionarTodo = (event) => {
        let states = {
            aceptar: true,
            seleccionar: true
        };
        setState(states);
        cookieSesion(states);
        setOpen(false);
    };

    const cookieSesion = (states) => {
        localStorage.setItem("cookieICR", JSON.stringify(states));
    };

    useEffect(() => {
        if(props.load && localStorage.getItem("cookieICR") === null) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [props.load]);

    useEffect(() => {
        if(localStorage.getItem("cookieICR") !== null){
            setOpen(false);
        }
    });

    return (
        <>
            <Dialog 
                disableEscapeKeyDown={true} 
                open={open} 
                onClose={handleClose} 
                fullWidth={true} 
                maxWidth={'xl'}
                classes={{
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody,
                }}>
                <DialogContent className="content">
                    <Typography variant="body2" style={{ color: "black", margin: "20px" }} gutterBottom>{'Usamos cookies y tecnologías similares para proporcionar ciertas características, mejorar la experiencia del usuario y entregar contenido que sea relevante para sus intereses. En función de su finalidad, además de las cookies técnicamente necesarias, se pueden utilizar cookies de análisis y marketing. Al hacer clic en "Aceptar y continuar", declara su consentimiento para el uso de las cookies antes mencionadas. Aquí puede realizar ajustes detallados o revocar su consentimiento (en parte si es necesario) con efecto para el futuro. Para obtener más información, consulte nuestra política de privacidad.'}</Typography>
                    <Typography variant="body2" style={{ color: "black", margin: "20px" }} gutterBottom>{'Puede decidir qué cookies se utilizan seleccionando las opciones respectivas a continuación. Tenga en cuenta que su selección puede afectar la funcionalidad del servicio.'}</Typography>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={state.aceptar} onChange={handleChange} color="primary" name="aceptar" />}
                            label={<Typography variant="body2" style={{ color: "black", marginTop: "7px" }} gutterBottom>{"Cookies técnicamente necesarias: Le permiten navegar y utilizar las funciones básicas y almacenar preferencias."}</Typography>}
                            style={{ color: "black", margin: "10px" }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.seleccionar} onChange={handleChange} color="primary" name="seleccionar" />}
                            label={<Typography variant="body2" style={{ color: "black", marginTop: "7px" }} gutterBottom>{"Cookies de análisis: nos permiten determinar cómo interactúan los visitantes con nuestro servicio para mejorar la experiencia del usuario."}</Typography>}
                            style={{ color: "black", margin: "10px" }}
                        />
                    </FormGroup>
                    <Button variant="contained" style={{ margin: "10px" }} onClick={aceptarContinuar}>Aceptar y continuar</Button>
                    <Button variant="contained" color="primary" style={{ margin: "10px" }} onClick={seleccionarTodo}>Seleccionar todo y continuar</Button>
                </DialogContent>
            </Dialog>
        </>
    );
}