import React, { useState, useEffect } from "react";

import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Cookies from "components/Cookies/Cookies";

import logo from "assets/img/logotipo.svg";

import styles from "assets/jss/material-kit-react/views/components.js";

import AtencionSection from "views/SolucionesPage/Sections/AtencionSection.js";
import BackOfficeSection from "views/SolucionesPage/Sections/BackOfficeSection.js";
import CobranzaSection from "views/SolucionesPage/Sections/CobranzaSection.js";
import VentasSection from "views/SolucionesPage/Sections/VentasSection.js";

const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function SolucionesPage(props) {
    const classes = useStyles();
    const { ...rest } = props;

    const [solucion, setSolucion] = useState('');
    const [load, setLoad] = useState(false);

    const SolucionSection = () => {
        switch (solucion) {
            case 'ventas':
                return(<VentasSection {...rest}/>);
            case 'atencion':
                return(<AtencionSection {...rest}/>);
            case 'cobranza':
                return(<CobranzaSection {...rest}/>);
            case 'back':
                return(<BackOfficeSection {...rest}/>);
            default:
                return(<VentasSection {...rest}/>);
        }
    }

    useEffect(() => {
        setLoad(true);
        window.scrollTo(0, 0);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const p = urlParams.get('p');

        if(p !== "" && p !== null){
            switch (p) {
                case 'ventas':
                    setSolucion('ventas');
                    break;
                case 'atencion':
                    setSolucion('atencion');
                    break;
                case 'cobranza':
                    setSolucion('cobranza');
                    break;
                case 'back':
                    setSolucion('back');
                    break;
                default:
                    setSolucion('ventas');
                    break;
            }
        } else {
            setSolucion('ventas');
        }
    });

    return (
        <div>
            <Header
                brand={logo}
                rightLinks={<HeaderLinks />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                height: 400,
                color: "white",
                }}
                {...rest}
            />

            <div style={{marginTop: "100px"}} className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <SolucionSection />
                </div>
            </div>
            <Cookies load={load} />
            <Footer />
        </div>
    );
}