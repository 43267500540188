import React, {useState, useEffect} from "react";

import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Cookies from "components/Cookies/Cookies";

import styles from "assets/jss/material-kit-react/views/components.js";

import logo from "assets/img/logotipo.svg";

const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function AvisoDePrivacidadPage(props){
    const classes = useStyles();
    const {...rest} = props;

    const [load, setLoad] = useState(false);

    useEffect(()=>{
        setLoad(true);
    }, []);

    return(
        <div>
            <Header
                brand={logo}
                rightLinks={<HeaderLinks />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                height: 400,
                color: "white",
                }}
                {...rest}
            />
            
            <div style={{marginTop: "150px"}} className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h4 style={{color: "black", textAlign: "center"}}>AVISO DE PRIVACIDAD</h4>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Su privacidad y confianza son muy importantes para nosotros y queremos asegurarnos que conozca como salvaguardamos la integridad, privacidad y protección de sus Datos Personales en ICR Calidad, Imagen y Resultados S.A. de C.V."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Se da a conocer este documento en estricto acatamiento a los artículos tercero transitorio, 16 y 17 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, publicada en el Diario Oficial de la Federación el día 5 de julio del año 2010, ordenamiento legal que tiene por objeto la protección de los datos personales en posesión de los particulares, con la finalidad de regular su tratamiento legítimo, controlado e informado, a efecto de garantizar la privacidad y el derecho a la autodeterminación informativa de las personas, así como de su Reglamento, publicado en el Diario Oficial de la Federación el 21 de diciembre de 2011 y los Lineamientos del Aviso de Privacidad, publicados en el mismo medio informativo el 17 de enero de 2013."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"El presente aviso de privacidad tiene como objeto informarle sobre el tratamiento que se le dará a los datos personales que sean recabados, utilizados, almacenados y/o transferidos por ICR Calidad Imagen y Resultados S.A. de C.V. y/o empresas filiales o subsidiarias (con quienes tiene convenio)."}
                    </Typography>
                    <h4 style={{color: "black"}}>IDENTIDAD Y DOMICILIO DEL RESPONSABLE.</h4>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"De acuerdo a lo previsto en la “Ley Federal de Protección de Datos Personales en Posesión de Particulares”, declara ICR Calidad, Imagen y Resultados S.A. de C.V., en adelante mencionada como ICR, ser una empresa legalmente constituida de conformidad con las leyes mexicanas, con domicilio en Avenida José López Portillo206, 4P, Colonia Zacauhititla, San Francisco Coacalco, Estado de México, CP 55700 ; y como responsable del uso y protección de sus datos personales, hace de su conocimiento que sus datos son tratados de forma estrictamente confidencial."}
                    </Typography>
                    <h4 style={{color: "black"}}>FINALIDAD DEL TRATAMIENTO DE LOS DATOS SOLICITADOS.</h4>
                    <h4 style={{color: "black"}}>COLABORADORES.</h4>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Dirigido a todo el personal en proceso de reclutamiento, selección, contratación, ya contratado por la empresa, que preste sus servicios para la empresa, filiales o subsidiarias (con quienes tiene convenio) y serán utilizados para los fines siguientes:"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {" • Para identificación, ubicación, comunicación y contacto con usted."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para envío o suministro de información de la empresa."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para reclutamiento y selección y administración de personal."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para evaluaciones psicométricas, socioeconómicas, competencias laborales, test de integridad (polígrafo),"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Alta en nuestras bases de datos."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para Servicios proporcionados como: mercadotécnicos, publicitarios o de prospección comercial."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para cumplimiento de obligaciones derivadas de operaciones laborales o prestación de servicios en las que es parte, ya sea como contrataciones, operaciones financieras, administrativas y pagos."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"De manera adicional, podremos usar su información para las siguientes finalidades, las cuales no son indispensables para la relación de las partes pero que facilitan una mejor atención para usted y retroalimentación para la empresa, como son:"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Análisis del desempeño laboral o profesional."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Información estadística."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Clima organizacional."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Los datos solicitados a los colaboradores o prestadores de servicios son:"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Datos de identificación: Nombre completo, Credencial de Elector y/o Pasaporte, comprobante de domicilio, dirección de correo electrónico, número telefónico, firma, RFC, CURP, fecha de nacimiento, edad, sexo, nacionalidad*, carta de antecedentes no penales*, referencias personales, estado civil y nombre de los padres."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Datos laborales: Puesto, área, tiempo laborado, motivo de baja, domicilio, dirección de correo electrónico y teléfono de trabajo."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Datos patrimoniales: Crédito de Infonavit, ingreso económico, número de IMSS, cuenta bancaria (en la que recibirá su pago)."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Datos académicos: Trayectoria educativa, nombre de la escuela, título, número de cédula, certificados."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Datos de salud: Estado de salud* y alergias a medicamentos."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Características personales: Tipo de sangre* y huella digital*."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Características físicas: Fotografía."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Los datos personales señalados con asterisco (*) son considerados sensibles y serán tratados con especial cuidado, con la finalidad no afectar su intimidad, por lo cual se requiere de su consentimiento. Los datos de identificación y contacto de las personas que usted haya designado como referencias; Al proporcionar estos datos usted reconoce tener el consentimiento de dichas personas para el tratamiento de sus datos con fines de contacto para validar la referencia."}
                    </Typography>
                    <h4 style={{color: "black"}}>CLIENTES Y PROVEEDORES.</h4>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Dirigido a todo el personal que proporciones datos a la Empresa, filiales o subsidiarias (con quienes tiene convenio)."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Los datos personales tanto sensibles como no sensibles que se recaben de usted, tendrán el uso que a continuación se describe, los cuales son necesarios para el desarrollo de la relación con la empresa:"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para identificación, ubicación, comunicación y contacto con usted."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para envío o suministro de información de la empresa, sus productos o servicios."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Alta en nuestras bases de datos con el fin de enriquecer nuestra cartera de clientes y proveedores."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para cumplimiento de obligaciones derivadas de operaciones mercantiles en las que es parte ya sea como contratos de suministro, contratos civiles, contratos de servicios, operaciones financieras y pagos."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para proporcionarlo a terceros con los que la empresa tenga celebrados acuerdos para la prestación de servicios que recibirá el usuario final."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"De manera adicional, podremos usar su información para las siguientes finalidades, las cuales no son indispensables para la relación de las partes pero que facilitan una mejor atención para usted y retroalimentación para la empresa, como son:"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Para análisis de cliente con la finalidad de ofrecer otros servicios."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Información estadística."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Encuestas de satisfacción del cliente."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Datos de identificación – nombre completo, domicilio, dirección de correo electrónico, número telefónico y RFC."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Datos laborales – puesto, área, domicilio, dirección de correo electrónico y teléfono de trabajo."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"En caso de no desear que sus datos personales sean tratados para los fines antes mencionados, o desee oponerse a compartir algún dato, usted lo puede comunicar al momento de proporcionar sus datos, o bien, posteriormente externándolo vía correo electrónico a la cuenta: contacto@grupo-icr.mx."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"De no recibirse notificación alguna de su parte, se entenderá que está de acuerdo en el uso que hagamos de sus datos personales."}
                    </Typography>
                    <h4 style={{color: "black"}}>DERECHOS QUE LE CORRESPONDEN.</h4>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"El titular de datos personales podrá hacer efectivos los derechos ARCO (acceso, rectificación, cancelación y oposición) respecto a al tratamiento de sus datos personales, dirigiendo su solicitud ante el Comité de Protección de Datos personales vía correo electrónico a la cuenta: contacto@grupo-icr.mx; asimismo podrá revocar en todo momento el consentimiento que haya otorgado y que fuere necesario para el tratamiento de sus datos personales, debiendo tomar en cuenta que hay datos que son necesarios para mantener la relación con usted, y que en caso de que usted solicitara la revocación de los mismos, nos impedirá mantener dicha relación, lo que derivaría en su terminación."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"La recepción de solicitudes podrá realizarse de lunes a viernes de 09:00 a 18:00 horas (exceptuando días festivos). Para lo anterior, deberá hacernos saber de forma detallada los datos personales a los que usted desea tener acceso, requiere rectificación, cancelación o revisión. Además, deberá contener los requisitos que solicita la Ley (Art. 29) para llevarlo a cabo:"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Nombre del titular y domicilio completo o cualquier otro medio para comunicarle la respuesta a la solicitud."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Documentos que acrediten la identidad del titular o en su caso, la representación legal de aquella persona que actúa a nombre del titular."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos mencionados (ARCO)."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"• Cualquier otro elemento o documento que facilite la localización de los datos personales. En caso de no recibirse notificación alguna de su parte, se entenderá que está de acuerdo con lo establecido en este documento."}
                    </Typography>
                    <h4 style={{color: "black"}}>COMUNICACIONES Y TRANSFERENCIAS DE DATOS PERSONALES.</h4>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"ICR se asegurará a través de la firma de convenios, que las empresas filiales, subsidiarias o con quienes se tengan acuerdos de prestación de servicios para usuario final mantengan las medidas de seguridad, administrativas, técnicas y físicas adecuadas para resguardar sus datos personales, así como que únicamente utilicen los datos personales para las finalidades por las cuales fueron contratados y de conformidad con el presente aviso de privacidad."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"ICR no cederá o venderá los datos personales (el uso será exclusivo para ICR, empresas de Grupo ICR, empresas filiales o subsidiarias o con quienes tiene convenio), en el caso en que exista la necesidad de transferir datos sensibles, ICR debe tener el consentimiento previo de los titulares. Sin embargo, ICR podrá transferir los datos personales sin consentimiento previo del titular, sólo en casos en que la Ley lo disponga."}
                    </Typography>
                    <h4 style={{color: "black"}}>CAMBIO DE AVISO DE PRIVACIDAD.</h4>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"ICR se reserva el derecho de enmendar o modificar en presente aviso de privacidad para cumplir con los cambios a la legislación sobre protección de datos o cumplir con disposiciones internas de ICR."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"ICR pondrá a su disposición el aviso de privacidad actualizado cuando se realicen ajustes, así como cuando se requiera de su consentimiento."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        {"La versión actualizada del aviso de privacidad está disponible en http://www.grupo-icr.mx"}
                    </Typography>
                    <Typography style={{marginBottom: "30px"}} variant="body2" color="textSecondary" component="p" paragraph>
                        {"Fecha de actualización 19 -Agosto-2019"}
                    </Typography>
                </div>
            </div>
            <Cookies load={load} />
            <Footer />
        </div>
    );
}