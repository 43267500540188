import React from "react";

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Business, HomeWork, AddShoppingCart} from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function VentasSection() {
  const elementos = [
    {
      titulo: 'B2B',
      contenido: 'Nuestros esfuerzos irán orientados a mantener al cliente y evitar que se vaya a la competencia a través de ofertas específicas realizadas por comerciales expertos.',
      icono: Business,
      color: '#EFB509'
    },
    {
      titulo: 'B2C',
      contenido: 'Se implementará una estrategia personalizada para sus diferentes nichos de mercado, creando así, la conexión deseada y con nuestro alto nivel de conversión transformar sus leads en clientes, mediante la comunicación efectiva y cerrando las ventas en un corto periodo de tiempo. Así optimizamos los recursos para que usted supere sus expectativas, incrementando sus ventas y ampliando sus horizontes de mercado.',
      icono: HomeWork,
      color: '#CD7213'
    },
    {
      titulo: 'Ventas Digitales',
      contenido: 'Diversificación de canales para poder brindar un mayor alcance y potencializar las ventas, adaptándose a las nuevas tendencias para garantizar un posicionamiento efectivo.',
      icono: AddShoppingCart,
      color: '#002C54'
    }
  ];

  return(
    <GridContainer>
      <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
        <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
          Ventas
        </Typography>
      </GridItem>
      <GridItem style={{textAlign: "center", marginBottom: '30px'}} xs={12} sm={12} md={12}>
        <Typography style={{textAlign: "justify"}} variant="body1" gutterBottom>
          Se proporcionará una atención cordial en conjunto de soluciones efectivas a las problemáticas de sus clientes, 
          utilizando recursos como lo son la escucha activa, empatizar y resolución de conflictos, brindando una excelente experiencia de servicio al cliente.
          Todos estos esfuerzos sumados son para garantizar la satisfacción con la atención que han recibido.
        </Typography>
      </GridItem>
      {elementos.map((item) => (
        <GridItem xs={12} sm={12} md={4}>
          <Paper style={{minHeight: "380px", textAlign: 'center', marginBottom: '30px'}} elevation={3}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{color: "black", marginTop: "50px"}} gutterBottom>
                  {item.titulo}
                </Typography>                    
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <item.icono style={{ fontSize: '70px', color: item.color}} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body2" style={{color: "black", marginTop: "10px", textAlign: 'justify', marginLeft: '20px', marginRight: '20px', marginBottom: '20px'}} gutterBottom>
                  {item.contenido}
                </Typography>
              </GridItem>
            </GridContainer>
          </Paper>
        </GridItem>
      ))}
    </GridContainer>
  );
}