import React, {useState, useEffect} from "react";

import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Adjust from '@material-ui/icons/Adjust';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {Phone, Mail, WhatsApp} from "@material-ui/icons";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Cookies from "components/Cookies/Cookies";

import logo from "assets/img/logotipo.svg";

import styles from "assets/jss/material-kit-react/views/components.js";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ContactoPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const matchesMax600 = useMediaQuery('(max-width:600px)');
    const matchesMin600 = useMediaQuery('(min-width:600px)');
    const matchesMin768 = useMediaQuery('(min-width:768px)');
    const matchesMin992 = useMediaQuery('(min-width:992px)');
    const matchesMin1200 = useMediaQuery('(min-width:1200px)');

    const [load, setLoad] = useState(false);

    const [solucion, setsolucion] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [severity, setSeverity] = useState('');
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const soluciones = [
        'Campaña de Venta',
        'Campaña de Cobranza',
        'Campaña de Atención a Clientes',
        'Encuestas',
        'Back Office'
    ];

    const handleChange = (event) => {
        setsolucion(event.target.value);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const SendEmailContacto = async (data) => {
        let dataResponse;
        try{
            let header = {method: "POST", body: JSON.stringify(data), headers: {'Content-Type': 'application/json'}};

            let response = await fetch(process.env.REACT_APP_URL_APICORE + "Correo/SendEmailContacto", header);
            dataResponse = await response.json();
        } catch(error){
            setOpenSnackbar(true);
            setSnackbarMessage("Intentelo más tarde");
            setSeverity("error");
        }

        return dataResponse;
    };

    const Enviar = (data) => {
        setOpenBackdrop(true);

        SendEmailContacto(data)
        .then((result) => {
            if(result.success){
                setOpenSnackbar(true);
                setSnackbarMessage("Envío exitoso");
                setSeverity("success");
            }

            setOpenBackdrop(false);
        })
        .catch(error => {
            setOpenSnackbar(true);
            setSnackbarMessage("Error interno");
            setSeverity("error");

            setOpenBackdrop(false);
        });
    };

    useEffect(()=>{
        setLoad(true);
    }, []);

    return (
        <div>
            <Header
                brand={logo}
                rightLinks={<HeaderLinks />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                height: 400,
                color: "white",
                }}
                {...rest}
            />

            <div style={{marginTop: "100px"}} className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                            <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                                Tenemos la mejor infraestructura para tus proyectos
                            </Typography>
                        </GridItem>
                        <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={6}>
                                    <Typography variant="h6" style={{color: "black", marginTop: "50px"}} gutterBottom>
                                        Ingrese los siguientes datos para recibir información personalizada
                                    </Typography>
                                    <form autoComplete="off">
                                        <GridContainer>
                                            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                                                <TextField id="nombre" style={{margin: "5px", minWidth: matchesMin768 || matchesMin992 || matchesMin1200 ? '210px' : ( matchesMin600 ? '320px' : (matchesMax600 ? '280px' : '200px')) }} label="Nombre" variant="outlined" />
                                                <TextField id="telefono" style={{margin: "5px", minWidth: matchesMin768 || matchesMin992 || matchesMin1200 ? '210px' : ( matchesMin600 ? '320px' : (matchesMax600 ? '280px' : '200px')) }} label="Teléfono" variant="outlined" />
                                            </GridItem>
                                            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                                                <TextField id="correo" style={{margin: "5px", minWidth: matchesMin768 || matchesMin992 || matchesMin1200 ? '210px' : ( matchesMin600 ? '320px' : (matchesMax600 ? '280px' : '200px')) }} label="Correo" variant="outlined" />
                                                <TextField id="empresa" style={{margin: "5px", minWidth: matchesMin768 || matchesMin992 || matchesMin1200 ? '210px' : ( matchesMin600 ? '320px' : (matchesMax600 ? '280px' : '200px')) }} label="Empresa" variant="outlined" />
                                            </GridItem>
                                            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                                                <FormControl variant="outlined" style={{minWidth: matchesMin768 || matchesMin992 || matchesMin1200 ? '430px' : (matchesMin600 ? '320px' : (matchesMax600 ? '280px' : '200px')), margin: "10px"}}>
                                                    <InputLabel id="soluciones-label">Soluciones</InputLabel>
                                                    <Select
                                                        labelId="soluciones-label"
                                                        id="soluciones-select"
                                                        value={solucion}
                                                        onChange={handleChange}
                                                        label="Soluciones"
                                                    >
                                                    <MenuItem value={1}>Campaña de Venta</MenuItem>
                                                    <MenuItem value={2}>Campaña de Cobranza</MenuItem>
                                                    <MenuItem value={3}>Campaña de Atención a Clientes</MenuItem>
                                                    <MenuItem value={4}>Encuestas</MenuItem>
                                                    <MenuItem value={5}>Back Office</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                                                <TextField
                                                    id="mensaje"
                                                    label="Mensaje"
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    style={{minWidth: matchesMin768 || matchesMin992 || matchesMin1200 ? '430px' : (matchesMin600 ? '320px' : (matchesMax600 ? '280px' : '200px')), margin: "10px"}}
                                                />
                                            </GridItem>
                                            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                                                <Button onClick={() => {
                                                    Enviar({
                                                        Nombre: document.getElementById("nombre").value,
                                                        Telefono: document.getElementById("telefono").value,
                                                        Correo: document.getElementById("correo").value,
                                                        Empresa: document.getElementById("empresa").value,
                                                        Soluciones: soluciones[parseInt(solucion) -1],
                                                        Mensaje: document.getElementById("mensaje").value
                                                    })
                                                }} variant="contained" color="primary" style={{margin: "10px"}}>
                                                    Enviar
                                                </Button>
                                            </GridItem>
                                        </GridContainer>                                      
                                    </form>
                                </GridItem>
                                <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={6}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Typography variant="h6" style={{color: "black", marginTop: "50px"}} gutterBottom>
                                                ¿Preguntas? Contactanos
                                            </Typography>
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <Button
                                                color="transparent"
                                                href="https://wa.me/+525518399933/?text=Hola%2C+Me+gustaria+informacion+acerca+de+"
                                                target="_blank"
                                                className={classes.navLink}
                                            >
                                                <WhatsApp style={{color: "green"}} />
                                                <span style={{marginLeft: "10px"}}>5518399933</span>
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <Button
                                                color="transparent"
                                                href="tel://5568133614"
                                                target="_blank"
                                                className={classes.navLink}
                                            >
                                                <Phone style={{color: "#002C54"}} />
                                                <span style={{marginLeft: "10px"}}>5568133614</span>
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <Button
                                                color="transparent"
                                                href="mailto://contacto@grupo-icr.mx"
                                                target="_blank"
                                                className={classes.navLink}
                                            >
                                                <Mail style={{color: "#CD7213"}} />
                                                <span style={{marginLeft: "10px"}}>contacto@grupo-icr.mx</span>
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                    <Typography variant="h6" style={{color: "black", marginTop: "50px"}} gutterBottom>
                                        Sucursales
                                    </Typography>
                                    <List component="nav" aria-label="main mailbox folders">
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Adjust />
                                            </ListItemIcon>
                                            <ListItemText primary="EDO MEX (MATRIZ)" secondary={
                                                <a href="https://goo.gl/maps/UEGHYzeQwtTyoUuU9" target="_blank"><Typography variant="body2" style={{color: "black", margin: "20px"}} gutterBottom>{"Dirección: Av José López Portillo #206 Int. 4p, Col. Zacuautitla Coacalco Estado de México CP 55700"}</Typography></a>
                                            } />
                                        </ListItem>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Adjust />
                                            </ListItemIcon>
                                            <ListItemText primary="CIUDAD DE MÉXICO" secondary={
                                                <a href="https://goo.gl/maps/1ktyy5EeoYQqQAnx6" target="_blank"><Typography variant="body2" style={{color: "black", margin: "20px"}} gutterBottom>{"Dirección: Acapulco 36, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX"}</Typography></a>
                                            } />
                                        </ListItem>
                                    </List>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={severity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Cookies load={load} />
            <Footer />
        </div>
    );
}