import React, {useState, useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ShowChart, LocalLibrary, Gavel, HeadsetMic } from "@material-ui/icons";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Cookies from "components/Cookies/Cookies";

import styles from "assets/jss/material-kit-react/views/components.js";

import logo from "assets/img/logotipo.svg";
import valores from "assets/img/valores.jpg";
import exe from "assets/img/exe.jfif";

const useStyles = makeStyles((theme) => ({
  ...styles
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const matchesMax600 = useMediaQuery('(max-width:600px)');

  const [load, setLoad] = useState(false);

  const nuestros_valores = [
    {
      titulo: 'Excelencia',
      valor: 'Ofrecemos los mejores resultados, logrando que cumplan sus objetivos con colaboradores altamente capacitados para brindarle el mejor servicio.',
      icono: ShowChart,
      color: '#EFB509'
    },
    {
      titulo: 'Responsabilidad',
      valor: 'Cada persona que integran nuestro equipo cumple con sus tareas, es efi0ciente y responde ante ellas, para garantizar su total satisfacción.',
      icono: LocalLibrary,
      color: '#CD7213'
    },
    {
      titulo: 'Honestidad',
      valor: 'Siempre le brindaremos una conducta recta, confiable y correcta, en donde predomina la confianza y la sinceridad tanto en lo que se hace, como en lo que se piensa, lo que se aprende, se ejecuta y el compromiso con el que se desarrollan las campañas.',
      icono: Gavel,
      color: '#002C54'
    },
    {
      titulo: 'Vocación de Servicio',
      valor: 'El cliente es la prioridad de todo lo que hacemos. Buscamos proactivamente brindarle el mejor servicio, aportarle valor añadido. Con esto podemos garantizar la fidelidad para su empresa.',
      icono: HeadsetMic,
      color: '#16253D'
    }
  ]

  useEffect(()=>{
    setLoad(true);
  }, []);

  return (
    <div>
      <Header
        color="transparent"
        brand={logo}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      
      <div style={{marginTop: "100px"}} className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
              <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                Quienes somos
              </Typography>
            </GridItem>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
              <Typography style={{textAlign: "justify"}} variant="body1" gutterBottom>
                La familia ICR somos una empresa 100% mexicana con 25 años de experiencia,conformada por ejecutivos profesionales y emprendedores. Estamos comprometidos para enlazarte con sus clientes y mediante la comunicación efectiva lograr que superes tus objetivos, dando a todo aquel que nos contacte un servicio con altos estándares de calidad y resultados efectivos.
              </Typography>
              <Typography style={{textAlign: "justify"}} variant="body1" gutterBottom>
                Nos establecimos como un centro de contacto para ventas telefónicas siendo pioneros en la venta de Seguros Paperless, expertos en la venta de Tarjetas de Crédito y extendiendo nuestras actividades a proyectos de Cobranza,Atención a Clientes y Back Office.
              </Typography>
              <Typography style={{textAlign: "justify"}} variant="body1" gutterBottom>
                A través de los años hemos diversificado e incluido nuevas líneas de negocio, como lo son proyectos de Marketing Digital y membresía de servicios médicos en expansión.
              </Typography>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={6}>
                  <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                    Misión
                  </Typography>
                  <Typography variant="body1" style={{marginTop: "50px", textAlign: "justify"}} gutterBottom>
                    Lograr que las campañas de nuestros clientes alcancen el éxito y cumplan sus objetivos, ofreciendo un servicio profesional y eficaz para sus necesidades.
                  </Typography>
                  <img style={{ width: matchesMax600 ? "300px" : "500px", height: matchesMax600 ? "200px" : "300px" }} src={valores}></img>
                </GridItem>
                <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={6}>
                  <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                    Visión
                  </Typography>
                  <Typography variant="body1" style={{marginTop: "50px", textAlign: "justify"}} gutterBottom>
                    Atender a las principales empresas del país y posicionarnos como el principal proveedor de servicios.
                  </Typography>
                  <img style={{ width: matchesMax600 ? "300px" : "500px", height: matchesMax600 ? "200px" : "300px", marginTop: "24px" }} src={exe}></img>
                </GridItem>
              </GridContainer>        
            </GridItem>           
          </GridContainer>
        </div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
              <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                Nuestros Valores
              </Typography>
            </GridItem>
            {nuestros_valores.map((item) => (
              <GridItem xs={6} sm={6} md={3}>
                <Paper style={{minHeight: "356px", textAlign: 'center', marginBottom: '30px'}} elevation={3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant="body1" style={{color: "black", marginTop: "50px"}} gutterBottom>
                        {item.titulo}
                      </Typography>                    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <item.icono style={{ fontSize: '70px', color: item.color}} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant="body2" style={{color: "black", marginTop: "10px", textAlign: 'justify', marginLeft: '20px', marginRight: '20px', marginBottom: '20px'}} gutterBottom>
                        {item.valor}
                      </Typography>
                    </GridItem>
                  </GridContainer>
                </Paper>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
      <Cookies load={load} />
      <Footer />
    </div>
  );
}
