/*eslint-disable*/
import React from "react";

import { useHistory, Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import {Phone, Mail} from "@material-ui/icons";

import Button from "components/CustomButtons/Button.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  let history = useHistory();

  const handleClick = (e, ruta) => {
    history.push(ruta);
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => handleClick(e, '')}
          style={{'&:hover': {
            backgroundColor: '#fff',
            color: '#3c52b2',
          }}}
        >
          Inicio
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => handleClick(e, 'quienes-somos')}
        >
          Quienes somos
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Soluciones"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          hoverColor="primary"
          dropdownList={[
            <Link to="/soluciones?p=ventas" className={classes.dropdownLink}>
              Ventas
            </Link>,
            <Link to="/soluciones?p=atencion" className={classes.dropdownLink}>
              Atención a Clientes
            </Link>,
            <Link to="/soluciones?p=cobranza" className={classes.dropdownLink}>
              Cobranza
            </Link>,
            <Link to="/soluciones?p=back" className={classes.dropdownLink}>
              Back Office
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => handleClick(e, 'contacto')}
        >
          Contacto
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => handleClick(e, 'bolsa-de-trabajo')}
        >
          Bolsa de trabajo
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => handleClick(e, 'aviso-de-privacidad')}
        >
          Aviso de privacidad
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="whatsapp"
          title="Contactanos en whatsapp"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://wa.me/+525518399933/?text=Hola%2C+Me+gustaria+informacion+acerca+de+"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-whatsapp"} style={{color: "green"}} />
            5518399933
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="phone"
          title="Contactanos al número"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="tel://5568133614"
            target="_blank"
            className={classes.navLink}
          >
            <Phone style={{color: "#002C54"}} />
            5568133614
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="phone"
          title="Contactanos al correo"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="mailto://contacto@grupo-icr.mx"
            target="_blank"
            className={classes.navLink}
          >
            <Mail style={{color: "#CD7213"}} />
            contacto@grupo-icr.mx
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
