import React, {useState, useEffect} from "react";

import classNames from "classnames";

import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Cookies from "components/Cookies/Cookies";

import logo from "assets/img/logotipo.svg";
import businesswoman from "assets/img/businesswoman.jpg";

import styles from "assets/jss/material-kit-react/views/components.js";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function BolsaPage(props) {
    const classes = useStyles();
    const { ...rest } = props;

    const [load, setLoad] = useState(false);

    const [solucion, setsolucion] = useState('');
    const [title, setTitle] = useState('');
    const [subheader, setSubheader] = useState('');
    const [description, setDescription] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [severity, setSeverity] = useState('');

    const Enviar = (data) => {
        setOpenDialog(false);
        setOpenBackdrop(true);

        SendEmailTrabajo(data)
        .then((result) => {
            if(result.success){
                setOpenSnackbar(true);
                setSnackbarMessage("Envío exitoso");
                setSeverity("success");
            }

            setOpenBackdrop(false);
        })
        .catch(error => {
            setOpenSnackbar(true);
            setSnackbarMessage("Error interno");
            setSeverity("error");

            setOpenBackdrop(false);
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleClickOpen = (title, subheader, description) => {
        setOpenDialog(true);
        setTitle(title);
        setSubheader(subheader);
        setDescription(description);
    };

    const handleChange = (event) => {
        setsolucion(event.target.value);
    };

    const SendEmailTrabajo = async (data) => {
        let dataResponse;
        try{
            let header = {method: "POST", body: JSON.stringify(data), headers: {'Content-Type': 'application/json'}};

            let response = await fetch(process.env.REACT_APP_URL_APICORE + "Correo/SendEmailTrabajo", header);
            dataResponse = await response.json();
        } catch(error){
            setOpenSnackbar(true);
            setSnackbarMessage("Intentelo más tarde");
            setSeverity("error");
        }

        return dataResponse;
    };

    let trabajosCDMX = [
        {
            title: "Ejecutivo Telefónico CDMX",
            subheader: "Con o sin experiencia",
            image: businesswoman,
            description:  `ACTIVIDAD: Colocación de un producto financiero para importante institución bancaria, CONTRATACIÓN INMEDIATA, PRESTACIONES, AGUINALDO, VACACIONES`
        }
    ];

    let trabajosEDOMEX = [
        {
            title: "Ejecutivo Telefónico EDOMEX",
            subheader: "Con o sin experiencia",
            image: businesswoman,
            description:  `ACTIVIDAD: Colocación de un producto financiero para importante institución bancaria, CONTRATACIÓN INMEDIATA, PRESTACIONES, AGUINALDO, VACACIONES`
        }
    ];

    useEffect(()=>{
        setLoad(true);
    }, []);

    return (
        <div>
            <Header
                brand={logo}
                rightLinks={<HeaderLinks />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                height: 400,
                color: "white",
                }}
                {...rest}
            />

            <div style={{marginTop: "100px"}} className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                            <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
                                Nuestras vacantes
                            </Typography>
                        </GridItem>
                        <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" style={{width: "80%",margin: "10px"}}>
                                <InputLabel id="soluciones-label">Vacantes</InputLabel>
                                <Select
                                    labelId="soluciones-label"
                                    id="soluciones-select"
                                    value={solucion}
                                    onChange={handleChange}
                                    label="Vacantes"
                                >
                                <MenuItem value={1}>Vacantes CDMX Col. Roma</MenuItem>
                                <MenuItem value={2}>Vacantes EDOMEX Coacalco</MenuItem>
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem style={{textAlign: "center", marginTop: "50px", marginBottom: "50px"}} xs={12} sm={12} md={12}>
                            {
                                solucion == 1 ?
                                trabajosCDMX.map(item => (
                                    <Card className={classes.root}>
                                        <CardHeader
                                            title={item.title}
                                            subheader={item.subheader}
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={item.image}
                                            title={item.title}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {
                                                    item.description
                                                }
                                            </Typography>
                                            <Button onClick={(e) => {handleClickOpen(item.title, item.subheader, item.description)}} variant="contained" color="primary" style={{margin: "10px"}}>
                                                Postulate
                                            </Button>
                                        </CardContent>
                                    </Card>
                                ))
                                :
                                trabajosEDOMEX.map(item => (
                                    <Card className={classes.root}>
                                        <CardHeader
                                            title={item.title}
                                            subheader={item.subheader}
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={item.image}
                                            title={item.title}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {
                                                    item.description
                                                }
                                            </Typography>
                                            <Button onClick={(e) => {handleClickOpen(item.title, item.subheader, item.description)}} variant="contained" color="primary" style={{margin: "10px"}}>
                                                Postulate
                                            </Button>
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {subheader}
                    </DialogContentText>
                    <TextField
                        style={{marginTop: "10px", marginBottom: "10px"}}
                        id="nombre"
                        label="Nombre completo"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        style={{marginTop: "10px", marginBottom: "10px"}}
                        id="celular"
                        label="Teléfono celular"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        style={{marginTop: "10px", marginBottom: "10px"}}
                        id="correo"
                        label="Correo electrónico"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        style={{marginTop: "10px", marginBottom: "10px"}}
                        id="horario"
                        label="Horario disponible"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        style={{marginTop: "10px", marginBottom: "10px"}}
                        id="comentarios"
                        label="Comentarios"
                        variant="outlined"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { 
                        Enviar({
                            Nombre: document.getElementById("nombre").value, 
                            Celular: document.getElementById("celular").value, 
                            Correo: document.getElementById("correo").value, 
                            Horario: document.getElementById("horario").value,
                            Comentarios: document.getElementById("comentarios").value, 
                            Title: title, 
                            Subheader: subheader, 
                            Description: description
                        })
                    }} color="primary">
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={severity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Cookies load={load} />
            <Footer />
        </div>
    );
}