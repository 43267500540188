/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import Typography from '@material-ui/core/Typography';
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Facebook, LinkedIn, Instagram } from "@material-ui/icons";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  
  return (
    <footer className={footerClasses}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={3}>
              <a href="https://goo.gl/maps/UEGHYzeQwtTyoUuU9" target="_blank"><Typography variant="body2" style={{color: "black", margin: "20px", textAlign: 'center'}} gutterBottom>{"Dirección Edo Mex: Av José López Portillo #206 Int. 4p, Col. Zacuautitla Coacalco Estado de México CP 55700"}</Typography></a>
            </GridItem>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={3}>
              <a href="https://goo.gl/maps/1ktyy5EeoYQqQAnx6" target="_blank"><Typography variant="body2" style={{color: "black", margin: "20px", textAlign: 'center'}} gutterBottom>{"Dirección CDMX: Acapulco 36, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX"}</Typography></a>
            </GridItem>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={2}>
              <Button
                color="transparent"
                href="https://www.facebook.com/grupoicr1/"
                target="_blank"
                className={classes.navLink}
              >
                <Facebook style={{color: "#002C54", height: '50px', width: '50px'}} />
                Facebook
              </Button>
            </GridItem>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={2}>
              <Button
                color="transparent"
                href="https://www.linkedin.com/company/grupoicr"
                target="_blank"
                className={classes.navLink}
              >
                <LinkedIn style={{color: "#002C54", height: '50px', width: '50px'}} />
                LinkedIn
              </Button>
            </GridItem>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={2}>
              <Button
                color="transparent"
                href="https://www.instagram.com/grupo.icr"
                target="_blank"
                className={classes.navLink}
              >
                <Instagram style={{color: "#002C54", height: '50px', width: '50px'}} />
                Instagram
              </Button>
            </GridItem>
            <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={2}>
              <div style={{padding: '35px 0 0 0'}}>
                &copy; {1900 + new Date().getYear()} ICR
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
