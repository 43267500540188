import React from "react";

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Assignment, Storage, HeadsetMic, Search, Cloud, Assessment} from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function CobranzaSection() {
  const elementos = [
    {
      titulo: 'Encuestas',
      contenido: 'Les ayudamos a recabar la información que necesitan sobre las opiniones, actitudes y comportamientos de sus clientes o públicos objetivos. Se aplica para descubrir una solución a un problema, e identificar e interpretar, de la manera más metódica posible, un conjunto de testimonios que puedan cumplir con el propósito establecido',
      icono: Assignment,
      color: '#EFB509'
    },
    {
      titulo: 'Higienización BDD',
      contenido: 'Es importante realizar una limpieza de los datos, ya que cuando provienen de diferentes fuentes pueden estar sujetos a errores y anomalías, mal cargados o con errores de transcripción los cuales pueden generar dobles cargas del mismo dato, incrementando así el espacio de almacenamiento con información inservible. El objetivo final de cualquier acción de la limpieza de la base de datos es mejorar la confianza de la organización en su información.',
      icono: Storage,
      color: '#CD7213'
    },
    {
      titulo: 'Seguimiento Postventa',
      contenido: 'Trae un gran diferencial para su empresa, son las acciones de postventa las que hacen que tu empresa sea recordada por los clientes. Cuánto más tu empresa es recordada y los clientes fieles compran de manera recurrente, más aumenta la frecuencia y la cantidad de ventas cerradas. Fidelizar clientes es una salida con costo inferior para vender más, si comparamos con la conquista de nuevos clientes.',
      icono: HeadsetMic,
      color: '#002C54'
    },
    {
      titulo: 'Prospectación',
      contenido: 'La búsqueda de clientes es un aspecto clave de la empresa, contamos con diversas técnicas de prospección para ayudarle a alcanzar sus objetivos. Le ayudamos a conseguir los mejores clientes potenciales, es decir, llegar a sus clientes ideales. Solo realizando una adecuada prospección, dispondrás de las personas ideales a las que poder ofrecer tus productos con más garantías de éxito.',
      icono: Search,
      color: '#16253D'
    },
    {
      titulo: 'Generación de Leads',
      contenido: 'Aumente sus posibilidades de venta de manera exponencial. Son varias las herramientas a través de las cuales se puede llegar a su objetivo: landing pages, CTA, mailing, redes sociales, etc. Herramientas que se pueden combinar con campañas offline que, le ayudarán en la captación y generación de leads.',
      icono: Cloud,
      color: '#EFB509'
    },
    {
      titulo: 'Reportes',
      contenido: 'Gracias a que la capacidad de generar reportes es prácticamente ilimitada, verá con exactitud lo que necesita. Esto facilita realizar un seguimiento del progreso, identificar las áreas que se deben mejorar y llevar a cabo los ajustes necesarios. Realizamos un seguimiento de las métricas que más le importan, como los índices de resolución, los tiempos de atención y la satisfacción del cliente. Comparamos los datos históricos y en tiempo real en una sola vista. Filtramos, guardamos y exportamos vistas únicas en segundos. También obtenemos detalles más profundos con un solo clic.',
      icono: Assessment,
      color: '#CD7213'
    }
  ];

  return(
    <GridContainer>
      <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
        <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
            Back Office 
        </Typography>
      </GridItem>
      <GridItem style={{textAlign: "center", marginBottom: '30px'}} xs={12} sm={12} md={12}>
        <Typography style={{textAlign: "justify"}} variant="body1" gutterBottom>
            Manejo y automatización de procesos comerciales integrados, desde análisis y control de captura de documentos hasta
            redacción y ejecución de contratos, soporte de ventas y servicio al consumidor durante todo el ciclo de relación. necesidades y ciclo
            de vida de su cartera.
        </Typography>
      </GridItem>
      {elementos.map((item) => (
        <GridItem xs={12} sm={12} md={4}>
          <Paper style={{minHeight: "450px", textAlign: 'center', marginBottom: '30px'}} elevation={3}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{color: "black", marginTop: "50px"}} gutterBottom>
                  {item.titulo}
                </Typography>                    
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <item.icono style={{ fontSize: '70px', color: item.color}} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body2" style={{color: "black", marginTop: "10px", textAlign: 'justify', marginLeft: '20px', marginRight: '20px', marginBottom: '20px'}} gutterBottom>
                  {item.contenido}
                </Typography>
              </GridItem>
            </GridContainer>
          </Paper>
        </GridItem>
      ))}
    </GridContainer>
  );
}