import React from "react";

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {MonetizationOn, Beenhere, ChromeReaderMode, HeadsetMic, AddShoppingCart} from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function AtencionSection() {
  const elementos = [
    {
      titulo: 'Retención',
      contenido: 'Nuestros esfuerzos irán orientados a mantener al cliente y evitar que se vaya a la competencia a través de ofertas específicas realizadas por comerciales expertos.',
      icono: MonetizationOn,
      color: '#EFB509'
    },
    {
      titulo: 'Fidelización',
      contenido: 'Incentivar a los clientes a continuar adquiriendo sus productos o servicios, premiando su comportamiento de compra mediante un trato directo para lograr que el cliente se sienta valorado por la empresa. También se realizan detecciones de debilidades mediante la gestión de la retroalimentación, activa que nos brindan los comentarios de los clientes, recordatorios sistemáticos de los detractores y/o acciones de protección y lealtad en clientes "sensibles"y "de valor", apoyo específico y total a los clientes en sus acciones cuando una situación cambia.',
      icono: Beenhere,
      color: '#CD7213'
    },
    {
      titulo: 'Reservas',
      contenido: 'Gestionar la ocupación real del establecimiento de manera remota, incluso cuántas plazas están ocupadas en cada momento. Atendiendo al cliente lo antes posible, tomando correctamente los datos del cliente, brindar la información pertinente para la resolución de todas las dudas.',
      icono: ChromeReaderMode,
      color: '#002C54'
    },
    {
      titulo: 'Soporte Técnico',
      contenido: 'Se brindará la asesoría personalizada para la resolución de dudas, mediante vía telefónica o canales digitales, ya sea para colaboradores internos o personas externas (clientes) y se tenga un correcto funcionamiento. Esto brindará una experiencia satisfactoria de atención y creará un sentido de eficiencia que su empresa proyectará, mostrando que las inquietudes de todas las personas que tengan interacción son valoradas.',
      icono: HeadsetMic,
      color: '#16253D'
    },
    {
      titulo: 'Soluciones Digitales',
      contenido: 'Toda empresa que busque el éxito debe detener soluciones digitales, ofrecemos la optimización de su presencia online. Mediante el manejo de imagen en redes sociales, interacciones con los clientes y respuesta de mensajería instantánea como WhatsApp',
      icono: AddShoppingCart,
      color: '#EFB509'
    }
  ];

  return(
    <GridContainer>
      <GridItem style={{textAlign: "center"}} xs={12} sm={12} md={12}>
        <Typography variant="h4" style={{color: "black", marginTop: "50px"}} gutterBottom>
            Atención a Clientes 
        </Typography>
      </GridItem>
      <GridItem style={{textAlign: "center", marginBottom: '30px'}} xs={12} sm={12} md={12}>
        <Typography style={{textAlign: "justify"}} variant="body1" gutterBottom>
          Se proporcionará una atención cordial en conjunto de soluciones efectivas a las problemáticas de sus clientes, 
          utilizando recursos como lo son la escucha activa, empatizar y resolución de conflictos, brindando una excelente experiencia de servicio al cliente.
          Todos estos esfuerzos sumados son para garantizar la satisfacción con la atención que han recibido.
        </Typography>
      </GridItem>
      {elementos.map((item) => (
        <GridItem xs={12} sm={12} md={4}>
          <Paper style={{minHeight: "430px", textAlign: 'center', marginBottom: '30px'}} elevation={3}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{color: "black", marginTop: "50px"}} gutterBottom>
                  {item.titulo}
                </Typography>                    
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <item.icono style={{ fontSize: '70px', color: item.color}} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body2" style={{color: "black", marginTop: "10px", textAlign: 'justify', marginLeft: '20px', marginRight: '20px', marginBottom: '20px'}} gutterBottom>
                  {item.contenido}
                </Typography>
              </GridItem>
            </GridContainer>
          </Paper>
        </GridItem>
      ))}
    </GridContainer>
  );
}