import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import QuienesSomosPage from "views/QuienesSomosPage/QuienesSomosPage.js";
import SolucionesPage from "views/SolucionesPage/SolucionesPage.js";
import ContactoPage from "views/ContactoPage/ContactoPage.js";
import BolsaPage from "views/BolsaPage/BolsaPage.js";
import AvisoDePrivacidad from "views/AvisoDePrivacidad/AvisoDePrivacidad.js";


var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/contacto" exact render={props => <ContactoPage {...props} /> } />
      <Route path="/soluciones" exact render={props => <SolucionesPage {...props} /> } />
      <Route path="/quienes-somos" exact render={props => <QuienesSomosPage {...props} /> } />
      <Route path="/bolsa-de-trabajo" exact render={props => <BolsaPage {...props} /> } />
      <Route path="/aviso-de-privacidad" exact render={props => <AvisoDePrivacidad {...props} /> } />
      <Route path="/" exact render={props => <LandingPage {...props} /> } />
      <Redirect to="/"></Redirect>
    </Switch>
  </Router>,
  document.getElementById("root")
);